<script setup>
import { onMounted, ref } from "vue";
import {
  CBreadcrumb,
  CBreadcrumbItem,
} from "@coreui/vue/dist/esm/components/breadcrumb/index.js";
import { useI18n } from "vue-i18n";
import { CIcon } from "@coreui/icons-vue";
import { useLocaleStore } from "~/stores/locale.js";
import { useMenusStore } from "~/stores/menu.js";
const { t } = useI18n();
const breadcrumbs = ref();
const localeStore = useLocaleStore();
const menusStore = useMenusStore();
const router = useRouter();
const getBreadcrumbs = () => {
  let ret = [];
  const route = useRoute();
  const paths = splitPath(route.path);
  if (paths.length > 1) {
    for (let i = 0; i < paths.length - 1; i++) {
      const menu = menusStore.getMenuByAlias(paths[i]);
      if (menu) {
        ret.push({
          name: menu.name,
          icon: menu.icon,
        });
      }
    }
  }
  ret = ret.concat(
    router.currentRoute.value.matched.map((route) => {
      let name = route.name;
      let icon;
      if (route.path === "/") {
        name = t("page.name.dashboard");
        icon = "cil-chart";
      } else if (route.meta && route.meta.nameAlias) {
        name = t(route.meta.nameAlias);
        if (route.meta) {
          icon = route.meta.icon;
        }
      } else {
        icon = "cib-circle";
        name = toPascalCase(name);
      }
      return {
        active: route.path === router.currentRoute.value.path,
        name,
        icon,
        path: `${router.options.history.base}${route.path}`,
      };
    }),
  );
  return ret;
};

router.afterEach(() => {
  breadcrumbs.value = getBreadcrumbs();
});

onMounted(() => {
  breadcrumbs.value = getBreadcrumbs();
});

const route = useRoute();
watch(
  () => route.path,
  () => {
    nextTick(() => {
      breadcrumbs.value = getBreadcrumbs();
    });
  },
  {
    immediate: true,
    deep: true,
  },
);
watch(
  () => localeStore.getLocale,
  () => {
    nextTick(() => {
      breadcrumbs.value = getBreadcrumbs();
    });
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>

<template>
  <CBreadcrumb class="my-0 justify-content-end">
    <CBreadcrumbItem
      v-for="(item, itemIndex) in breadcrumbs"
      :key="'breadcrumb-' + item.name + '-' + itemIndex"
      class="text-end"
      :href="item.active ? '' : item.path"
      :active="item.active"
    >
      <CIcon :content="item.icon" />&nbsp;
      {{ item.name }}
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<style scoped>
.breadcrumb-item {
  border-bottom: 1px solid var(--cui-border-color);
  padding: 0.2rem;
}
</style>
